<template>
  <div class="addvip">
    <h3>添加会员</h3>
    <div class="border">
      <div class="box">
        <span>请输入vip名称</span>
        <input type="text" v-model="obj.vip_name"/>
        <!-- <div class="span">月</div> -->
      </div>
      <div class="box">
        <span>请输入价格</span>
        <input type="number" v-model.number="obj.vip_price"/>
        <div class="span">元</div>
      </div>
      <div class="box">
        <span>请输入天数</span>
        <input type="number" v-model.number="obj.day"/>
        <div class="span">天</div>
      </div>
      <div class="box">
        <span>每月免费聊天次数</span>
        <input type="number" v-model.number="obj.number" />
        <div class="span">次</div>
      </div>
      <div class="box">
        <span>请输入vip分红</span>
        <input type="text" v-model.number="obj.prorata"/>
        <div class="span">%</div>
      </div>
      <div class="box">
        <span>请输入vip描述</span>
        <input type="text" v-model="obj.vip_remark"/>
        <!-- <div class="span">月</div> -->
      </div>
      <el-button @click="CreateVip">确认新增</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      obj: {
        vip_name: "颜值vip",
        vip_price: 1,
        prorata: 0,
        vip_remark: "这是一个非常帅气的vip，购买以后会增加你的颜值",
        day:"",
        number: 0,
      },
    };
  },
  mounted() {
    // this.InviterGiveNumList();
  },

  methods: {
    async CreateVip() {
      this.obj.vip_price=this.obj.vip_price*100
      let res = await this.$http.post("CreateVip", this.obj);
      console.log(res);
      if (res.status !== 200) {
        this.$message.error(res.data.msg);
      } else {
          // window.sessionStorage.setItem("token",res.data.data.token)
        console.log(res);
        //   console.log(res.data.data);
        this.$message({
            message: "添加成功",
            type: "success",
          });
        this.order = res.data.data;
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.border {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #dcdfe6;
}
h3 {
  margin: 0 0 40px 0;
}
.addvip {
  box-sizing: border-box;
  padding: 20px;
}
.box > span {
  float: left;
  width: 10rem;
}
.span {
  float: right;
  width: 5rem;
}
input {
  float: left;
  width: calc(100% - 15rem);
  background: white;
  border: 1px solid #dcdfe6;
  padding: 5px;
  box-sizing: border-box;
}
.box {
  overflow: hidden;
  margin: 20px 0;
}
.el-button {
  width: 10rem;
}
</style>
