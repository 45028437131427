<template>
  <div class="addai">
    <h3>编辑ai角色</h3>
    <el-form
      :model="dynamicValidateForm"
      ref="dynamicValidateForm"
      label-width="100px"
      class="demo-dynamic"
    >
      <el-form-item prop="ai_role_name" label="角色名称">
        <el-input v-model="dynamicValidateForm.ai_role_name"></el-input>
      </el-form-item>
      <el-form-item prop="ai_role_name" label="角色标签">
        <el-input v-model="dynamicValidateForm.iabel"></el-input>
      </el-form-item>
      <el-form-item
        v-for="(domain, index) in dynamicValidateForm.ai_role_msgs"
        :label="'对话' + index"
        :key="domain.key"
        :prop="'ai_role_msgs.' + index + '.msg'"
        :rules="{
          required: true,
          message: '对话不能为空',
          trigger: 'blur',
        }"
      >
        <el-input v-model="domain.msg"></el-input
        ><el-button @click.prevent="removeDomain(domain)" class="del"
          >删除</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="AlterAiRole">提交</el-button>
        <el-button @click="addDomain">新增对话</el-button>
        <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-upload
      class="avatar-uploader"
      action="http://124.220.205.119:8083/Upload2"
      :show-file-list="false"
      list-type="picture-card"
      :on-success="handleAvatarSuccess"
    >
      <img v-if="imageUrl" width="100%" :src="dynamicValidateForm.img_url" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script>
export default {
  data() {
    return {
      obj: this.$route.query.param,
      imageUrl: "",
      id:"",
      loadingBtn: false,
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      dynamicValidateForm: {
        ID:"",
        img_url: "",
        ai_role_msgs: [
          {
            msg: "",
          },
        ],
        iabel:"",
        ai_role_name: "",
      },
    };
  },
  mounted(){
    this.AiRoleView()
  },
  methods: {
    //上传图片
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(file);
      console.log(res);
      this.dynamicValidateForm.img_url =
        "http://124.220.205.119:8083/" + res.data;
      // console.log(this.imageUrl)
      // this.dynamicValidateForm.img_url= URL.createObjectURL(file.raw)img_url
    },
    //获取ai信息
    async AiRoleView() {
      this.id=this.obj.ID
      const formData = new FormData();
      formData.append("id", this.id);
      let res = await this.$http.post("AiRoleView", formData);
      console.log(res)
    //   this.dynamicValidateForm.ai_role_msgs.msg=res.data.data.aiRoleMsg.msg
    console.log(res.data.data.aiRoleMsg,"res.data.data.aiRoleMsg.msg")
    this.dynamicValidateForm.ai_role_msgs=res.data.data.aiRoleMsg.map((item,index)=>{
        console.log(item)
        return{
        msg:item.msg
        }
      })
      this.dynamicValidateForm.img_url=res.data.data.aiRole.img_url
      this.dynamicValidateForm.iabel=res.data.data.aiRole.iabel
      this.dynamicValidateForm.ai_role_name=res.data.data.aiRole.ai_role_name
      this.dynamicValidateForm.ID=Number(res.data.data.id)
      //+这个赋值你自己改下，我不知道改成什么
      this.imageUrl=res.data.data.aiRole.img_url
      if (res.status !== 200) {
        alert("失败");
      } else {
        const formData = new FormData();
        //   window.sessionStorage.setItem("token",res.data.token)
      }
    },
    //修改ai信息
    async AlterAiRole() {
      let res = await this.$http.post("AlterAiRole", this.dynamicValidateForm);
      //+这个赋值你自己改下，我不知道改成什么
    //   this.imageUrl=res.data.data.aiRole.img_url
      if (res.status !== 200) {
        alert("失败");
      } else {
        console.log(res)
        console.log(this.dynamicValidateForm)
      }
    },
    //重置对话
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //删除对话
    removeDomain(item) {
      var index = this.dynamicValidateForm.ai_role_msgs.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.ai_role_msgs.splice(index, 1);
      }
    },
    //新增对话
    addDomain() {
      this.dynamicValidateForm.ai_role_msgs.push({
        value: "",
        key: Date.now(),
      });
    },
  },
};
</script>

<style scoped>
.addai {
  box-sizing: border-box;
  padding: 20px;
}
/deep/.el-form-item__content {
  margin-left: 30px !important;
}
/deep/.el-input {
  width: calc(100% - 150px) !important;
  float: left !important;
}
/* /deep/.el-input__inner{
      width: calc(100% - 200px) !important;
   } */
.del {
  float: right;
}
input {
  width: calc(100% - 100px) !important;
}
h3 {
  margin-bottom: 20px;
}
</style>
