<template>
    <div class="addai">
      <h3>新增客服</h3>
      <el-form
        :model="obj"
        ref="dynamicValidateForm"
        label-width="100px"
        class="demo-dynamic"
      >
        <el-form-item prop="ai_role_name" label="客服手机号">
          <el-input v-model="obj.phone"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="AfterSaleCreate">提交</el-button>
        </el-form-item>
      </el-form>
      <el-upload
        class="avatar-uploader"
        action="http://124.220.205.119:8083/Upload2"
        :show-file-list="false"
        list-type="picture-card"
        :on-success="handleAvatarSuccess"
      >
        <img v-if="imageUrl" width="100%"  :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        imageUrl: "",
        obj:{
            phone:"",
            imgUrl:""
        }
      };
    },
    methods: {
      //上传图片
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
        console.log(file)
        console.log(res)
        this.obj.imgUrl='http://124.220.205.119:8083/'+res.data
        // console.log(this.imageUrl)
      },
      //添加客服
      async AfterSaleCreate() {
        let res = await this.$http.post("AfterSaleCreate", this.obj);
        console.log(res);
        if (res.status !== 200) {
          this.$message.error(res.data.msg);
        } else {
          //   window.sessionStorage.setItem("token",res.data.token)
          this.$message({
              message: "添加成功",
              type: "success",
            });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .addai {
    box-sizing: border-box;
    padding: 20px;
  }
  /deep/.el-form-item__content {
    margin-left: 30px !important;
  }
  /deep/.el-input {
    width: calc(100% - 150px) !important;
    float: left !important;
  }
  /* /deep/.el-input__inner{
      width: calc(100% - 200px) !important;
   } */
  .del {
    float: right;
  }
  input {
    width: calc(100% - 100px) !important;
  }
  h3 {
    margin-bottom: 20px;
  }
  </style>
  