<template>
  <div class="firstlogin">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="vip_name" label="会员名称"></el-table-column>
      <el-table-column prop="vip_price" label="会员价格"> </el-table-column>
      <el-table-column prop="prorata" label="会员分红"></el-table-column>
      <el-table-column prop="day" label="天数"></el-table-column>
      <el-table-column prop="vip_remark" label="描述"></el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            type="primary"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // obj:{
      ID: "",
      // },
      tableData: [
        {
          // CreatedAt: "",//开始日期
          // UpdatedAt: "",//结束日期
          vip_name: "", //名称
          vip_price: "", //价格
          prorata: "", //比例
          // DeletedAt:""
          day: "", //天数
          desc: "", //描述
        },
      ],
    };
  },
  mounted() {
    this.VipList();
  },
  methods: {
    async VipList() {
      let res = await this.$http.post("VipList", "");
      console.log(res);
      if (res.status !== 200) {
        alert("失败");
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        console.log(res);
        this.tableData = res.data.data;
        console.log(this.tableData);
      }
    },
    //跳转详情
    handleEdit(index, row) {
      console.log(row);
      this.$router.push({
        name: "vip",
        query: { param: row },
      });
    },
    //删除
    async handleDelete(index, row) {
      console.log(row);
      this.ID = row.ID;
      const formData = new FormData();
      formData.append("id", this.ID);
      //let res = await this.$http.post("DeletVip", this.obj,FormData);
      let res = await this.$http.post("DeletVip", formData);
      if (res.status !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.$message({
            message: "删除成功",
            type: "success",
          });
        //   window.sessionStorage.setItem("token",res.data.token)
        this.VipList()
      }
    },
  },
};
</script>
<style scoped>
.firstlogin {
  box-sizing: border-box;
  padding: 20px;
}
</style>
