<template>
  <div class="firstlogin">
    <el-table :data="dynamicValidateForm" style="width: 100%">
      <el-table-column prop="user_name" label="用户名称"> </el-table-column>
      <el-table-column prop="phone" label="用户账号"> </el-table-column>
      <el-table-column prop="Money" label="用户可提现金额"> </el-table-column>
      <el-table-column prop="vip_level" label="vip"> </el-table-column>
      <el-table-column prop="number" label="永久聊天次数"> </el-table-column>
      <el-table-column prop="img_url" label="用户头像">
        <template slot-scope="scope">
          <img :src="scope.row.img_url" width="50" height="50" class="cover" />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :page-size="obj.pageSize"
      :pager-count="9"
      @current-change="handleCurrentChange"
      layout="prev, pager, next"
      :total="num"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      obj: {
        page: 1,
        pageSize: 5,
      },
      num: "",
      dynamicValidateForm: [],
    };
  },
  mounted() {
    this.Users();
  },
  methods: {
    //分页
    handleCurrentChange(newPage) {
      this.obj.page = newPage
      console.log(newPage)
      // 再次请求数据
      this.Users()
    },

    //获取ai信息
    async Users() {
      let res = await this.$http.post("Users", this.obj);
      console.log(res);
      this.dynamicValidateForm = res.data.data.users;
      this.num = res.data.data.nums;
      // this.imageUrl=res.data.data.aiRole.img_url
      // if (res.status !== 200) {
      //   alert("失败");
      // } else {
      //   const formData = new FormData();
      //   //   window.sessionStorage.setItem("token",res.data.token)
      // }
    },
  },
};
</script>
<style scoped>
.firstlogin {
  box-sizing: border-box;
  padding: 20px;
}
</style>
