import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import Home from '../views/HomeView.vue'
import about from '../views/AboutView.vue'
import vip from '../views/vip.vue'
import firstlogin from '../views/firstlogin.vue'
import addrole from '../views/addrole.vue'
import additional from '../views/additional.vue'
import addvip from '../views/addvip.vue'
import code from '../views/code.vue'
import modifycode from '../views/modifycode.vue'
import modifyadditional from '../views/modifyadditional.vue'
import additionallist from '../views/additionallist.vue'
import viplist from '../views/viplist.vue'
import recharge from '../views/recharge.vue'
import addai from '../views/addai.vue'
import ailist from '../views/ailist.vue'
import additionals from '../views/additionals.vue'
import give from '../views/give.vue'
import role from '../views/role.vue'
import rolelist from '../views/rolelist.vue'
import history from '../views/history.vue'
import ai from '../views/ai.vue'
import create from '../views/create.vue'
import freeze from '../views/freeze.vue'
import thaw from '../views/thaw.vue'
import addae from '../views/addae.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: login,
    meta: {
      shovalues: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about,
  },
  {
    path: '/modifyadditional',
    name: 'modifyadditional',
    component: modifyadditional,
  },
  {
    path: '/ai',
    name: 'ai',
    component: ai,
  },
  {
    path: '/vip',
    name: 'vip',
    component: vip,
    // props: route => ({param: route.query.param})
  },
  {
    path: '/role',
    name: 'role',
    component: role,
  },
  {
    path: '/additionals',
    name: 'additionals',
    component: additionals,
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    // redirect: '/vip',//页面默认加载的路由
    children: [
      {
        path: '/create',
        name: 'create',
        component: create,
      },
      {
        path: '/addae',
        name: 'addae',
        component: addae,
      },
      {
        path: '/thaw',
        name: 'thaw',
        component: thaw,
      },
      {
        path: '/freeze',
        name: 'freeze',
        component: freeze,
      },
      {
        path: '/viplist',
        name: 'viplist',
        component: viplist,
      },
      {
        path: '/history',
        name: 'history',
        component: history,
      },

      {
        path: '/give',
        name: 'give',
        component: give,
      },
      {
        path: '/addai',
        name: 'addai',
        component: addai,
      },
      {
        path: '/rolelist',
        name: 'rolelist',
        component: rolelist,
      },
      {
        path: '/recharge',
        name: 'recharge',
        component: recharge,
      },
      {
        path: '/additional',
        name: 'additional',
        component: additional,
      },
      {
        path: '/firstlogin',
        name: 'firstlogin',
        component: firstlogin,
      },
      {
        path: '/code',
        name: 'code',
        component: code,
      },
      {
        path: '/additionallist',
        name: 'additionallist',
        component: additionallist,
      },
      {
        path: '/ailist',
        name: 'ailist',
        component: ailist,
      },
      {
        path: '/modifycode',
        name: 'modifycode',
        component: modifycode,
      },
      {
        path: '/addrole',
        name: 'addrole',
        component: addrole,
      },
      {
        path: '/addvip',
        name: 'addvip',
        component: addvip,
      },]
  }
]

const router = new VueRouter({
  routes
})

export default router
