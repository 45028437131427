<template>
  <div class="addvip">
    <h3>添加额外提供次数套餐</h3>
    <div class="border">
      <!-- <div class="box">
        <span>套餐名称</span>
        <input type="text" />
        <div class="span"></div>
      </div> -->
      <div class="box">
        <span>套餐价格价格</span>
        <input type="number" v-model.number="obj.extra_price"/>
        <div class="span">元</div>
      </div>
      <div class="box">
        <span>套餐聊天次数</span>
        <input type="number" v-model.number="obj.extra_name"/>
        <div class="span">次</div>
      </div>
      <el-button @click="CreateExtraNum">确认新增</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      obj: {
        extra_name: "",
        extra_price: null,
      },
    };
  },
  mounted() {
    // this.CreateExtraNum();
  },
  methods: {
    async CreateExtraNum(index, row) {
      this.obj.extra_price=this.obj.extra_price*100
      console.log(this.obj);
      let res = await this.$http.post("CreateExtraNum", this.obj);
      console.log(res);
      if (res.status !== 200) {
        this.$message.error(res.data.msg);
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        this.$message({
            message: "添加成功",
            type: "success",
          });
        console.log(this.obj);
      }
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.border {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #dcdfe6;
}
h3 {
  margin: 0 0 40px 0;
}
.addvip {
  box-sizing: border-box;
  padding: 20px;
}
.box > span {
  float: left;
  width: 10rem;
}
.span {
  float: right;
  width: 5rem;
}
input {
  float: left;
  width: calc(100% - 16rem);
  background: white;
  border: 1px solid #dcdfe6;
  padding: 5px;
}
.box {
  overflow: hidden;
  margin: 20px 0;
}
.el-button {
  width: 10rem;
}
</style>
