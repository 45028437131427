<template>
    <div class="addvip">
        <h3>解冻用户</h3>
        <div class="border">
            <div class="box">
                <span>赠送账号</span>
                <input type="number" v-model="phone">
            </div>
            <!-- <div class="box">
                <span>赠送次数</span>
                <input type="number">
                <div class="span">次</div>
            </div> -->
            <el-button @click="NoFreezeUser">确认解冻</el-button>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
    
            phone:''
    
      };
    },
    mounted() {
    // this.AlterVip();
  },
    methods: {
      async NoFreezeUser(index, row)  {
        
        const formData = new FormData();
        formData.append("phone", this.phone);
      let res = await this.$http.post("NoFreezeUser", formData);
      console.log(res);
      if (res.status !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.$message({
            message: "成功",
            type: "success",
          });
        //   window.sessionStorage.setItem("token",res.data.token)
        console.log(this.obj);
      }
    },
    },
  };
  </script>
<style scoped>
*{
    margin: 0;
    padding: 0;
}
.border{
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #DCDFE6;
}
h3{
    margin: 0 0 40px 0;
}
.addvip{
    box-sizing: border-box;
    padding: 20px;
}
.box>span{
    float: left;
    width: 10rem;
}
.span{
    float: right;
    width: 5rem;
}
input{
    float: left;
    width: calc(100% - 16rem);
    background: white;
    border: 1px solid #DCDFE6;
    padding: 5px;
}
.box{
    overflow: hidden;
    margin: 20px 0;
}
.el-button{
    width: 10rem;
}
</style>