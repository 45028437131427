<template>
  <div class="login_container no-navbar">
    <div class="login_box no-navbar" >
      <div class="avater_box">
        <img src="../assets/logo.png" alt="" />
      </div>
      <el-form
        :model="loginForm"
        :rules="rules"
        label-width="80px"
        class="login_form"
        ref="loginFormref"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="loginForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="password">
          <el-input v-model="loginForm.code"></el-input>
          <div class="code" @click="getyzm">
            {{ btnstr }}
          </div>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login()" class="button"
            >登录</el-button
          >
          <!-- <el-button type="info" @click="resetForm('loginFormref')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btnstr: "获取验证码",
      loginForm: {
        phone: "15751519721",
        code: "50116",
        openid: "oZPLY5vwowSBLGVnwmtittmaH46A",
        imgUrl: "http://ss",
        name: "吃饭吧",
      },

      rules: {
        phone: [
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        code: [
          {
            required: true,
            min: 6,
            max: 6,
            message: "请输入正确的验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    timewait(t) {
      var _this = this;
      setTimeout(function () {
        console.log(_this.btndisabled);
        if (t >= 0) {
          console.log(t);
          _this.btnstr = t + "秒后重新获取";
          t--;
          // _this.btndisabled = true
          _this.timewait(t);
        } else {
          _this.btnstr = "获取验证码";
          t = 60;
          _this.btndisabled = false;
        }
      }, 1000);
    },
    getyzm() {
      if (this.btndisabled) {
        return;
      }
      // 		//验证手机号码是否合法
      if (!/^1[13456789]\d{9}$/.test(this.loginForm.phone)) {
        alert("手机号不合法");
        return;
      }
      // const _this = this
      //请求发送验证码
      this.$refs.loginFormref.validate(async (valid) => {
        console.log(valid, "valid");
        if (!valid) return;
        let res = await this.$http.post("/create_code", this.loginForm);
        // console.log(resData);
        if (res.status !== 200) {
          console.log(res.status);
          //失败提示
          this.$message.error(res.data.msg);
        } else if (res.data.code == 2000) {
          //成功提示
          this.$message({
            message: res.data.msg,
          });
          window.sessionStorage.setItem("token", res.data.data.token);
          this.$setCookie("token", res.data.data.token);
          console.log(res.data.data.token);
          // alert(1)
          this.$router.push("/Home");
        }
      });
      this.timewait(60);
      this.btndisabled = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    login() {
      this.$refs.loginFormref.validate(async (valid) => {
        console.log(valid, "valid");
        if (!valid) return;
        let res = await this.$http.post("AdminLogin", this.loginForm);
        console.log(res);

        if (res.status !== 200) {
          console.log(res.status);
          //失败提示
          this.$message.error(res.data.msg);
        } else if (res.data.code == 2000) {
          //成功提示
          this.$message({
            message: "登录成功",
            type: "success",
          });
          window.sessionStorage.setItem("token", res.data.data.token);
          this.$setCookie("token", res.data.data.token);
          console.log(res.data.data.token);
          // alert(1)
          this.$router.push("/Home");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
nav{
  height: 0 !important;
}

.button {
  background: rgb(20, 164, 128);
  color: white;
}
.code {
  color: rgb(20, 164, 128);
  background-color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  border: 1px solid rgb(20, 164, 128);
  padding: 0 10px;
  border-radius: 5px;
  box-sizing: border-box;
  line-height: 20px;
  /* margin-top: 10rpx; */
}
.login_container {
  background-color: rgb(20, 164, 128);
  height: 100vh;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avater_box {
    width: 130px;
    height: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }

  .btns {
    display: flex;
    justify-content: end;
  }

  .login_form {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
  }
}
</style>
