<template>
  <div class="home">
    <div class="left">
      <el-col :span="12">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :router="true"
        >
          <!-- <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>导航一</span>
            </template>
            <el-menu-item-group>
            </el-menu-item-group>
            <el-submenu index="1-1">
              <template slot="title">选项1</template>
              <el-menu-item index="/vip">馒头</el-menu-item>
              <el-menu-item index="/firstlogin">首次登录</el-menu-item>
            </el-submenu>
            <el-submenu index="1-2">
              <template slot="title">选项2</template>
              <el-menu-item index="1-2-1">首次登录</el-menu-item>
            </el-submenu>
            <el-submenu index="1-3">
              <template slot="title">选项3</template>
              <el-menu-item index="1-3-1">选项1</el-menu-item>
            </el-submenu>
            <el-submenu index="1-4">
              <template slot="title">选项4</template>
              <el-menu-item index="1-4-1">选项1</el-menu-item>
            </el-submenu>
          </el-submenu> -->
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>汇总</span>
            </template>
            <el-menu-item-group>
            </el-menu-item-group>
            <el-submenu index="3-1">
              <template slot="title">管理员</template>
              <el-menu-item index="/addrole">添加管理员</el-menu-item>
              <el-menu-item index="/rolelist">管理员列表</el-menu-item>
              <el-menu-item index="/history">注册列表</el-menu-item>
              <el-menu-item index="/addae">添加客服</el-menu-item>
            </el-submenu>
            <el-submenu index="3-2">
              <template slot="title">赠送聊天次数</template>
              <el-menu-item index="/firstlogin">初次登录</el-menu-item>
              <el-menu-item index="/give">赠送次数</el-menu-item>
            </el-submenu>
            <el-submenu index="3-3">
              <template slot="title">邀请码</template>
              <!-- <el-menu-item index="/code">新增邀请码</el-menu-item> -->
              <el-menu-item index="/modifycode">修改邀请码</el-menu-item>
            </el-submenu>
            <el-submenu index="3-4">
              <template slot="title">新增提问套餐</template>
              <el-menu-item index="/additional">增加套餐</el-menu-item>
              <el-menu-item index="/additionallist">套餐列表</el-menu-item>
            </el-submenu>
            <el-submenu index="3-5">
              <template slot="title">会员</template>
              <el-menu-item index="/addvip">添加会员档次</el-menu-item>
              <el-menu-item index="/viplist">套餐列表</el-menu-item>
              <el-menu-item index="/create">会员介绍</el-menu-item>
            </el-submenu>
            <el-submenu index="3-6">
              <template slot="title">ai角色管理</template>
              <el-menu-item index="/addai">添加ai角色</el-menu-item>
              <el-menu-item index="/ailist">ai角色列表</el-menu-item>
            </el-submenu>
            <el-submenu index="3-7">
              <template slot="title">用户账户管理</template>
              <el-menu-item index="/freeze">冻结账户</el-menu-item>
              <el-menu-item index="/thaw">解除冻结</el-menu-item>
            </el-submenu>
            <!-- <el-submenu index="3-4">
              <template slot="title">选项4</template>
              <el-menu-item index="3-4-1">选项1</el-menu-item>
            </el-submenu> -->
          </el-submenu>
        </el-menu>
      </el-col>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex:"vip"
    };
  },
  watch:{
    // 监听路由变化
    '$route.path':{
      handler(routePath){
        this.handleSelect(routePath)
      }
    }
  },
  methods: {
    handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
    handleNodeClick(data) {
      console.log(data);
    },
    handleSelect(routePath) {
      // 路由跳转时，标记在导航的那个选项下对应的路由
      this.activeIndex = this.$route.path
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home{
  width: 100%;
  height: 100%;
}
.left {
  width: 15rem;
  min-height: 100vh;
  background-color: aliceblue;
  float: left;
  text-align: left;
}
.el-tree {
  background-color: aliceblue !important;
}
.el-col-12 {
  width: 100% !important;
}
.right {
  width: calc(100% - 15rem);
  float: left;
  /* min-height: 100vh; */
  max-height: 100vh;
  overflow: auto;
}
</style>
