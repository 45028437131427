<template>
    <div class="firstlogin">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="user_name" label="管理员名称" width="280"></el-table-column>
        <el-table-column prop="phone" label="管理员账号"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        phone:"",
        tableData: [
          {
            date: "10次",
            name: "大会员",
            address: "10元",
          },
        ],
      };
    },
    mounted(){
      this.AdminList()
    },
    methods: {
          //列表
    async AdminList() {
      let res = await this.$http.post("AdminList", "");
      console.log(res);
      if (res.status !== 200) {
        alert("失败");
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        this.tableData = res.data.data;
        console.log(this.tableData);
      }
    },
     //跳转详情
    handleEdit(index, row) {
      console.log(row);
      this.$router.push({
        name: "role",
        query: { param: row },
      });
    },
        //删除
        async handleDelete(index, row) {
      console.log(row);
      this.ID = row.phone;
      const formData = new FormData();
      formData.append("phone", this.phone);
      let res = await this.$http.post("DeletAdmin", formData);
      if (res.status !== 200) {
        alert("失败");
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        // console.log(res);
        this.AiRoleList()
      }
    },
    },
  };
  </script>
  <style scoped>
   .firstlogin {
    box-sizing: border-box;
    padding: 20px;
  }
  </style>
  