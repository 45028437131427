<template>
  <div id="app" >
    <nav>
      <div class="heade" v-if="!$route.meta.shovalues">
        <div class="left">河马ai后台管理系统</div>
        <div class="right">
          <span>{{name}}</span>
        </div>
      </div>
    </nav>
    <router-view/>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        name: "",
      };
    },
    mounted() {
      this.Index();
    },
  
    methods: {
      async Index() {
        // const formData = new FormData();
        // formData.append("phone", this.phone);
       let token= window.sessionStorage.getItem("token");
        console.log(token)
        let res = await this.$http.post("Index",token);
        // window.sessionStorage.setItem("token",res.data.data.token)

        console.log(res,'个人信息');
        if (res.status !== 200) {
          this.$message.error(res.data.msg);
        } else {
          console.log(res);
          res.data.data.user_name=this.name
        }
      },
    },
  };
  </script>
<style scoped>
*{
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.heade{
  border-bottom: solid 1px #e6e6e6;
  overflow: hidden;
  /* box-sizing: border-box; */
  padding:0 20px;
  cursor: pointer;
}
.left{
  float: left;
  font-weight: 700;
}
.right{
  width: 10rem;
  float: right;
  text-align: left;
  overflow: hidden;
}
.right>span{
  float: right;
}
nav {
  /* padding: 30px; */
  height: auto;
  line-height: 50px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
