<template>
  <div class="firstlogin">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="ai_role_name" label="ai角色名称">
      </el-table-column>
      <el-table-column prop="iabel" label="ai角色标签">
      </el-table-column>
      <el-table-column prop="img_url" width="380" label="图片">
        <template slot-scope="scope">
          <img :src="scope.row.img_url" alt="" width="40px" />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ID:"",
      tableData: [
        {
          name: "老师",
        },
      ],
    };
  },
  mounted() {
    this.AiRoleList();
  },
  methods: {
    //列表
    async AiRoleList() {
      let res = await this.$http.post("AiRoleList", "");
      console.log(res);
      if (res.status !== 200) {
        alert("失败");
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        this.tableData = res.data.data;
        console.log(this.tableData);
      }
    },
    //跳转详情
    handleEdit(index, row) {
      console.log(row);
      this.$router.push({
        name: "ai",
        query: { param: row },
      });
    },
    //删除
    async handleDelete(index, row) {
      console.log(row);
      this.ID = row.ID;
      const formData = new FormData();
      formData.append("id", this.ID);
      let res = await this.$http.post("DeletAiRole", formData);
      if (res.status !== 200) {
        this.$message.error(res.data.msg);
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        this.$message({
            message: "删除成功",
            type: "success",
          });
        this.AiRoleList()
      }
    },
  },
};
</script>
<style scoped>
.firstlogin {
  box-sizing: border-box;
  padding: 20px;
}
</style>
