import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'

//cookie
import { setCookie, getCookie, checkCookie, clearCookie } from '@/utils/cookie';
Vue.prototype.$setCookie = setCookie;
Vue.prototype.$getCookie = getCookie;
Vue.prototype.$checkCookie = checkCookie;
Vue.prototype.$clearCookie = clearCookie;


Vue.use(ElementUI);
// axios.defaults.baseURL = '/api' 
axios.defaults.baseURL="http://124.220.205.119:8083"
axios.interceptors.request.use(config=>{
 config.headers.token=window.sessionStorage.getItem("token")
 return config
})

Vue .prototype.$http=axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')















