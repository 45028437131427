<template>
  <div class="addai">
    <h3>新增ai角色</h3>
    <el-form
      :model="dynamicValidateForm"
      ref="dynamicValidateForm"
      label-width="100px"
      class="demo-dynamic"
    >
      <el-form-item prop="ai_role_name" label="角色名称">
        <el-input v-model="dynamicValidateForm.ai_role_name"></el-input>
      </el-form-item>
      <el-form-item prop="ai_role_name" label="角色标签">
        <el-input v-model="dynamicValidateForm.iabel"></el-input>
      </el-form-item>
      <el-form-item
        v-for="(domain, index) in dynamicValidateForm.ai_role_msgs"
        :label="'对话' + index"
        :key="domain.key"
        :prop="'ai_role_msgs.' + index + '.msg'"
        :rules="{
          required: true,
          message: '对话不能为空',
          trigger: 'blur',
        }"
      >
        <el-input v-model="domain.msg"></el-input
        ><el-button @click.prevent="removeDomain(domain)" class="del"
          >删除</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="CreateAiRole">提交</el-button>
        <el-button @click="addDomain">新增对话</el-button>
        <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <el-upload
      class="avatar-uploader"
      action="http://124.220.205.119:8083/Upload2"
      :show-file-list="false"
      list-type="picture-card"
      :on-success="handleAvatarSuccess"
    >
      <img v-if="imageUrl" width="100%"  :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageUrl: "",
      loadingBtn: false,
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      dynamicValidateForm: {
        img_url: "",
        iabel:"",
        ai_role_msgs: [
          {
            msg: "",
          },
        ],
        ai_role_name: "",
      },
    };
  },
  methods: {
    //上传图片
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      console.log(file)
      console.log(res)
      this.dynamicValidateForm.img_url='http://124.220.205.119:8083/'+res.data
      // console.log(this.imageUrl)
      // this.dynamicValidateForm.img_url= URL.createObjectURL(file.raw)img_url
    },
    //添加ai
    async CreateAiRole() {
      console.log(this.obj);
      let res = await this.$http.post("CreateAiRole", this.dynamicValidateForm);
      console.log(res);
      if (res.status !== 200) {
        this.$message.error(res.data.msg);
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        this.$message({
            message: "添加成功",
            type: "success",
          });
        console.log(this.dynamicValidateForm);
      }
    },
    //重置对话
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //删除对话
    removeDomain(item) {
      var index = this.dynamicValidateForm.ai_role_msgs.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.ai_role_msgs.splice(index, 1);
      }
    },
    //新增对话
    addDomain() {
      this.dynamicValidateForm.ai_role_msgs.push({
        value: "",
        key: Date.now(),
      });
    },
  },
};
</script>

<style scoped>
.addai {
  box-sizing: border-box;
  padding: 20px;
}
/deep/.el-form-item__content {
  margin-left: 30px !important;
}
/deep/.el-input {
  width: calc(100% - 150px) !important;
  float: left !important;
}
/* /deep/.el-input__inner{
    width: calc(100% - 200px) !important;
 } */
.del {
  float: right;
}
input {
  width: calc(100% - 100px) !important;
}
h3 {
  margin-bottom: 20px;
}
</style>
