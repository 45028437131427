<template>
  <div class="firstlogin">
    <h3>设置首次登录赠送聊天次数</h3>
    <!-- <div class="first">
                当前首次登录赠送10次聊天机会
        </div> -->
    <div class="border">
      <div class="modify">
        <span>当前首次登录赠送的聊天次数为</span>
        <input type="number" readonly :value="order" />
        <div class="span">次</div>
      </div>
      <div class="modify">
        <span>将首次登录赠送次数修改为</span>
        <input type="number" v-model.number="obj.num" />
        <div class="span" >次</div>
      </div>
      <el-button @click="CreateFirst">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      order:"",
      obj:{
        "num":0
      },
    };
  },
  mounted(){
　　this.First()
},

  methods: {
    async First() {
        let res = await this.$http.post("First","");
        console.log(res)
        if (res.status !== 200) {
          alert("失败");
        } else {
          //   window.sessionStorage.setItem("token",res.data.token)
          console.log(res,"First");
        //   console.log(res.data.data);
          this.order=res.data.data
        }
    },
    async CreateFirst() {
        let res = await this.$http.post("CreateFirst",this.obj);
        console.log(res)
        if (res.status !== 200) {
          alert("失败");
        } else {
          //   window.sessionStorage.setItem("token",res.data.token)
          console.log(res,"CreateFirst");
          console.log(this.obj.num,"this.obj.num")
          this.First()
        }
    },
  },
};
</script>

<style scoped>
* {
  /* margin: 0;
    padding: 0; */
}
.border {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #dcdfe6;
}
h3 {
  margin: 20px 0;
}
.first {
  margin-bottom: 40px;
  width: 100%;
}
.firstlogin {
  box-sizing: border-box;
  padding: 20px;
}
.modify > span {
  float: left;
  width: 15rem;
}
.span {
  float: right;
  width: 5rem;
}
input {
  float: left;
  width: calc(100% - 20rem);
  background: white;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  padding: 5px;
}
.modify {
  overflow: hidden;
  margin: 20px 0;
}
.el-button {
  width: 10rem;
}
</style>
