<template>
    <div class="firstlogin">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="date" label="充值时间" width="280"></el-table-column>
        <el-table-column prop="name" label="支付方式" width="280"></el-table-column>
        <el-table-column prop="address" label="充值金额"> </el-table-column>
        <!-- <el-table-column prop="address" label="所购买的套餐"> </el-table-column> -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        tableData: [
          {
            date: "10次",
            name: "大会员",
            address: "10元",
          },
        ],
      };
    },
    methods: {
      handleEdit(index, row) {
        console.log(index, row);
      },
      handleDelete(index, row) {
        console.log(index, row);
      },
    },
  };
  </script>
  <style scoped>
   .firstlogin {
    box-sizing: border-box;
    padding: 20px;
  }
  </style>
  