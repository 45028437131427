<template>
  <div class="firstlogin">
    <el-table :data="tableData" style="width: 100%">
      <!-- <el-table-column prop="date" label="套餐名称" width="280">
      </el-table-column> -->
      <el-table-column prop="extra_name" label="聊天次数"> </el-table-column>
      <el-table-column prop="extra_price" label="套餐价格"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // obj: {
        id: "",
      // },
      tableData: [
        {
          extra_name: "", //次数
          extra_price: "", //价格
        },
      ],
    };
  },
  mounted() {
    this.ExtraNumList();
  },
  methods: {
    //列表
    async ExtraNumList() {
      let res = await this.$http.post("ExtraNumList", "");
      console.log(res);
      if (res.status !== 200) {
        alert("失败");
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        console.log(res);
        this.tableData = res.data.data;
        console.log(this.tableData);
      }
    },
    //编辑
    handleEdit(index, row) {
      console.log(row);
      this.$router.push({
        name: "additionals",
        query: { param: row },
      });
    },
    //删除
    async handleDelete(index, row) {
      console.log(row);
      this.id=row.ID
      const formData = new FormData();
      formData.append("id", this.id);
      let res = await this.$http.post("DeletExtraNum", formData);
      if (res.status !== 200) {
        this.$message.error(res.data.msg);
      } else {
        //   window.sessionStorage.setItem("token",res.data.token)
        this.$message({
            message: "删除成功",
            type: "success",
          });
          this.ExtraNumList()
        console.log(res);
      }
    },
  },
};
</script>
<style scoped>
.firstlogin {
  box-sizing: border-box;
  padding: 20px;
}
</style>
