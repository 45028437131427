<template>
    <div class="addvip">
      <h3>添加会员</h3>
      <div class="border">
        <div class="box">
          <span>介绍会员</span>
          <textarea name="" id="" cols="30" rows="10" v-model="obj.text"></textarea>
          <!-- <div class="span">月</div> -->
        </div>
        <el-button @click="AnnouncementTxtCreate">确认新增</el-button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        obj: {
          text:""
        },
      };
    },
    mounted() {
      // this.InviterGiveNumList();
    },
  
    methods: {
      async AnnouncementTxtCreate() {
        let res = await this.$http.post("AnnouncementTxtCreate", this.obj);
        console.log(res);
        if (res.status !== 200) {
          this.$message.error(res.data.msg);
        } else {
            // window.sessionStorage.setItem("token",res.data.data.token)
          console.log(res);
          //   console.log(res.data.data);
          this.$message({
              message: "添加成功",
              type: "success",
            });
          this.order = res.data.data;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  * {
    margin: 0;
    padding: 0;
  }
  textarea{
    width:100%;
    height:200px ;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 10px;
  }
  .border {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #dcdfe6;
  }
  h3 {
    margin: 0 0 40px 0;
  }
  .addvip {
    box-sizing: border-box;
    padding: 20px;
  }
  .box > span {
    float: left;
    /* width: 10rem; */
  }
  .span {
    float: right;
    width: 5rem;
  }
  input {
    float: left;
    width: calc(100% - 15rem);
    background: white;
    border: 1px solid #dcdfe6;
    padding: 5px;
    box-sizing: border-box;
  }
  .box {
    overflow: hidden;
    margin: 20px 0;
  }
  .el-button {
    width: 10rem;
  }
  </style>
  