<template>
    <div class="addvip">
        <h3>编辑会员</h3>
        <div class="border">
            <div class="box">
                <span>会员名称</span>
                <input type="text" v-model="obj.vip_name">
                <div class="span"></div>
            </div>
            <div class="box">
                <span>会员价格</span>
                <input type="number" v-model.number="obj.vip_price">
                <div class="span">元</div>
            </div>
            <div class="box">
                <span>会员分红</span>
                <input type="number"  v-model.number="obj.prorata">
                <div class="span">%</div>
            </div>
            <div class="box">
                <span>请输入会员天数</span>
                <input type="number" v-model.number="obj.day">
                <div class="span">天</div>
            </div>
            <div class="box">
                <span>会员描述</span>
                <input type="text" v-model="obj.vip_remark">
                <!-- <div class="span">月</div> -->
            </div>
            <el-button @click="AlterVip">确认修改</el-button>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        obj : this.$route.query.param,
      };
    },
    mounted() {
    // this.AlterVip();
  },
    methods: {
      async AlterVip(index, row)  {
        console.log(this.obj);
        this.obj.vip_price=this.obj.vip_price*100
      let res = await this.$http.post("AlterVip", this.obj);
      console.log(res);
      if (res.status !== 200) {
        this.$message.error(res.data.msg);
      } else {
        this.$message({
            message: "修改成功",
            type: "success",
          });
        //   window.sessionStorage.setItem("token",res.data.token)
        console.log(this.obj);
      }
    },
    },
  };
  </script>
<style scoped>

*{
    margin: 0;
    padding: 0;
}
.border{
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #DCDFE6;
}
h3{
    margin: 0 0 40px 0;
}
.addvip{
    box-sizing: border-box;
    padding: 20px;
}
.box>span{
    float: left;
    width: 10rem;
}
.span{
    float: right;
    width: 5rem;
}
input{
    float: left;
    width: calc(100% - 15rem);
    background: white;
    border: 1px solid #DCDFE6;
    padding: 5px;
    box-sizing: border-box;
}
.box{
    overflow: hidden;
    margin: 20px 0;
}
.el-button{
    width: 10rem;
    padding: 10px 0;
}
</style>